/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: KWautfont1;
    src: url(Righteous-Regular.ttf);
}

@font-face {
    font-family: KWautfont2;
    src: url(WHATRG__.TTF);
}

@font-face {
    font-family: KWautfont3;
    src: url(BAARS___.TTF);
}


body {
    background-color: #112442;
    font-family: KWautfont3;
    color: rgba(255, 255, 255, 0.700);
}

.manutencao {
    align-items: center;
}

.topbarPosition {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #112442;
    overflow: hidden;
    z-index: 1000;
    border-bottom: 1px;
    border-bottom-style: outset;
    border-bottom-color: rgba(234, 240, 246, 0.2)
}

.menu
{
    padding: 10px;
    margin: 10px;
    color: azure;
    font-weight: bold;
}

.wpLogo {
    height: 50px;
    margin-left: auto;
    display: flex;
}

.footerPosition {
    max-width: fit-content;
    margin: auto;
    display: flex;
    position: static;
    bottom: 0;
    z-index: var(100);
    border-top: 1px;
    border-top-style: outset;
    border-top-color: rgba(234, 240, 246, 0.2)
}

.LTDA{
    font-size: 13px;
    margin: auto;
}


.contatoPosition {
    max-width: fit-content;
    display: grid;
    grid-template-columns: auto auto;
    margin: auto;
    position: static;
    margin-bottom: 10px;
    bottom: 0;
    z-index: var(100);
}

.contato {
    margin: auto;
    font-size: 15px;
    text-align: center;
}

.contatoLogo {
    height: 50px;
    margin: auto;
    margin-top: 10px;
    display: block;
}

.contatoPosition .redesSociais {
    max-width: fit-content;
    display: flex;
    margin: auto;
    margin-top: 10px;
    position: static;
}

.contatoPosition .redesSociais img{
    height: 30px;
    filter: drop-shadow(0px 99999px 0 #ffffff63);
    transform: translateY(-99999px);
    padding: 5px;
}

.contatoLines {
    max-width: fit-content;
    display: flex;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    position: static;
}

.contatoLines a{
    color: rgba(255, 255, 255, 0.700);
}

.contatoLines img{
    height: 30px;
    filter: drop-shadow(0px 99999px 0 #ffffff63);
    transform: translateY(-99999px);
    margin-right: 10px;
}

.contatoLines p{
    padding: 0;
    margin: 0;
    line-height: 20px;
}

.contatoPosition .redesSociais :hover img{
    filter: none;
    transform: none;
}

h1 {
    margin: auto;
    font-size: 20px;
    margin-top: 2px;
    margin-bottom: 10px;
    text-align: center;
    width: 300px;
}

.bannerPosition {
    max-width: 1400px;
    margin: auto;
    position: static;
    margin-top: 80px;
    z-index: var(100);
    height: 400px;
}

.bannerDescription {
    margin: auto;
    height: 160px;
    width: 300px;
    font-size: 15px;
    text-align: center;
}

.bannerApps {
    display: flex;
    margin-left: 70px;
    position: absolute;
    top: 250px;
    width: 300px;
    font-size: 15px;
    text-align: left;
}

.bannerItens {
    padding: 10px;
    margin: 10px;
    font-weight: bold;
    overflow: hidden;
}

.bannerItens img {
    margin: auto;
    filter: invert(75%);
    height: 140px;
    width: 140px;
    max-width: 140px;
    border-radius: 10%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.LIs {
    margin-left: 10px;
}

.trabalhosPosition {
    max-width: fit-content;
    margin: auto;
    position: static;
    top: 0;
    z-index: var(100);
    border-top: 1px;
    border-top-style: outset;
    border-top-color: rgba(234, 240, 246, 0.2);
    margin-bottom: 100px;
}

.trabalhosGrid {
    display: grid;
    grid-template-columns: auto auto auto;
    max-width: fit-content;
    margin: auto;
    position: static;
}

.trabalhosItens {
    position: relative;
    padding: 20px;
    margin: 10px;
    height: 390px;
    max-width: 300px;
    font-weight: bold;
    font-family: KWautfont3;
    background-color: rgba(255, 255, 255, 0.116);
    border-radius: 10%;
    text-align: center;
}

.trabalhosItens .owl-theme .owl-dots .owl-dot span {
    background: #D6D6D6!important; /* dots color*/
  }
 
  .trabalhosItens .owl-theme .owl-dots .owl-dot.active span,
  .owl-theme .owl-dots .owl-dot:hover span {
    background: #112442!important; /*active and on hover color*/
  }

owl-carousel-o img {
    height: 150px;
    max-width: 300px;
    border-radius: 10%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.trabDescription {
    height: 130px;
    font-size: 14px;
}

.bannerItens .owl-prev img, .bannerItens .owl-next img{
    filter: invert(75%);
    height: 60px;
    max-width: 30px;
}

.bannerItens .owl-prev:hover img, .bannerItens .owl-next:hover img{
    filter: invert(100%);
}

.bannerItens .owl-prev, .bannerItens .owl-next{
    background: none!important;
    max-width: 80px;
    position: absolute;
    top: 50px;
}

.bannerItens .owl-prev {
    left: -20px;
}

.bannerItens .owl-next{
    right: -20px;
}

.clientesPosition {
    max-width: fit-content;
    margin: auto;
    position: static;
    top: 0;
    z-index: var(100);
    border-top: 1px;
    border-top-style: outset;
    border-top-color: rgba(234, 240, 246, 0.2);
    margin-bottom: 10px;
    border-bottom: 1px;
    border-bottom-style: outset;
    border-bottom-color: rgba(234, 240, 246, 0.2);
}

.titulos{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    font-size: 25px;
}

.clientesGrid {
    max-width: fit-content;
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: auto;
    position: static;
    z-index: var(100);
}

.clientesItens{
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;
}

.clientesItens img{
    filter: grayscale(10%) opacity(50%);
    height: 120px;
}

.clientesItens .menorIMG{
    height: 50px;
}

.clientesItens:hover img{
    filter: none;
}

.contatoForm {
    text-align: center;
}

.contatoFormFields {
    padding: 6px;
}

.contatoFormFields label {
    display: block;
    margin-bottom: 2px;
}

.contatoFormFields .input {
    background-color:rgba(234, 240, 246, 0.1);
    border-color: rgba(234, 240, 246, 0.1);
    color: #D6D6D6;
    border-radius: 8px;
    font-size: 15px;
    padding: 5px;
}

.contatoFormFields .input:hover{
    background-color:rgba(234, 240, 246, 0.2);
    border-color: rgba(234, 240, 246, 0.3);
}

.contatoFormFields .textarea {
    background-color:rgba(234, 240, 246, 0.1);
    border-color: rgba(234, 240, 246, 0.1);
    color: #D6D6D6;
    border-radius: 8px;
    font-size: 15px;
    padding: 5px;
}

.contatoFormFields .textarea:hover{
    background-color:rgba(234, 240, 246, 0.2);
    border-color: rgba(234, 240, 246, 0.3);
}

.tituloMenor{
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 23px;
}

.contatoForm .button{
    background-color:rgba(234, 240, 246, 0.2);
    color: #D6D6D6;
    border: none;
    font-size: 15px;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.contatoForm .button:hover{
    background-color:rgba(234, 240, 246, 0.1);
    border: #112442;
}

.validatorText{
    color: red;
}

.recaptcha {
    padding: 6px;
    transform: scale(0.8);
}


/* Formatação para Celular */
@media (max-width:480px){
    .trabalhosGrid {
        grid-template-columns: auto;
    }

    .clientesGrid {
        grid-template-columns: auto auto;
    }

    .clientesItens img{
        height: 80px;
        filter: none;
    }

    .clientesItens .menorIMG{
        height: 30px;
    }

    .contatoPosition {
        grid-template-columns: auto;
    }

    .trabalhosItens {
        height: 440px;
    }
    
    .trabDescription {
        height: 170px;
    }

    .bannerPosition {
        height: 420px;
    }
    
    .bannerDescription {
        height: 200px;
    }
    
    .bannerApps {
        top: 280px;
    }

}